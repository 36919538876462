<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Proxy Status Check Report</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="row">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.time" class="form-control">
                                <option value="" selected>-- Any Time --</option>
                                <option v-for="time in timeSelector" :key="time" :value="time">{{time}}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.client" class="form-control">
                                <option value="" selected>-- Any Client --</option>
                                <option v-for="client in dropdownClients" :key="client._id" :value="client._id">{{client.name}}</option>
                            </select>
                        </div>


                                            <div class="form-group mr-1">
                                                <select v-model="filter.product" class="form-control">
                                                    <option value="" selected>-- Any Product--</option>
                                                    <option v-for="product in products" :key="product._id" :value="product._id">{{product.name}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group mr-1">
                                                <select class="form-control" v-model="filter.provider">
                                                    <option selected value="">--Any Provider--</option>
                                                    <option v-for="item in providers" :key="item" :value="item">{{item}}</option>
                                                </select>
                                            </div>


                                            <div  class="form-group mr-1">
                                                <select class="form-control" v-model="filter.status">
                                                    <option selected value="">--Any Status--</option>
                                                    <option v-for="item in statuses" :key="item" :value="item">{{item}}</option>
                                                </select>
                                            </div>
                                            <button
                                                type="button"
                                                class="btn text-white mr-1"
                                                @click="search"
                                                style="background-color: #383838; height:39px;"
                                            >
                                                <i class="fa fa-search" title="Search"></i>
                                                Search
                                            </button>
                                            <button
                                                style="height:39px;"
                                                type="button"
                                                class="btn btn-success mr-1"
                                                v-if="showExportButton"
                                                @click="exportToExcel"
                                            >
                                                <i
                                                    class="fa fa-download"
                                                    title="Export"
                                                >
                                                </i>
                                                Export
                                            </button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="report"
                        :allowExcelExport='true'
                        :allowPaging="true"
                        :allowSorting="true"
                        :allowGrouping='true'
                        :allowFiltering="true"
                        :allowResizing='true'
                        :groupSettings='groupSettings'
                        :filterSettings="filterSettings"
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column field='date' minWidth='8' width='130' headerText="Date"></e-column>
                            <e-column field='time_frame' minWidth='8' width='140' headerText="Time"></e-column>
                            <e-column field='time_frame' minWidth='8' width='140' headerText='Time Frame'></e-column>
                            <e-column field='provider' minWidth='8' width='140' headerText='Provider'></e-column>
                            <e-column field='id' minWidth='8' width='160' headerText='Proxy ID' :template="pTemplate"></e-column>
                            <e-column field='data_ip' minWidth='8' width='150'  headerText='IP'></e-column>
                            <e-column field='data_country' minWidth='8' width='100' headerText='Country'></e-column>
                            <e-column field='data_state' minWidth='8' width='110' headerText='State'></e-column>
                            <e-column field='data_city' minWidth='8' width='150' headerText='City'></e-column>
                            <e-column field='error_code' headerText='Error Code' minWidth='8' width='130'></e-column>
                            <e-column field='error_count' headerText='Error Count' minWidth='8' width='140'></e-column>
                            <e-column field='lastCheck' headerText='Time Last Checked'></e-column>
                            <e-column field='nextCheck' headerText='Time Next Checked'></e-column>

                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import axios from 'axios'
    import swal from "sweetalert";
    import Vue from "vue";
    import {
        GridPlugin,
        Page,
        Sort,
        ExcelExport,
        Group,
        Filter,
        Resize
    } from "@syncfusion/ej2-vue-grids";
    import pTemplate from "@/components/pTemplate";
    import sha1 from "js-sha1";


    Vue.use(GridPlugin);

    export default {
        name: 'ProxyReport',
        props:['user'],
        components:{

        },
        data: function () {
            return {
                filter:{
                    to: '',
                    from: '',
                    provider:"",
                    status: "",
                    time:'',
                    client: '',
                    product: '',
                },
                dropdownClients: [],
                clients: [],
                products: [],
                report:[],
                providers:[
                    'Didsoft',
                    "Crawlera",
                    "Flipnode",
                    "Homeip",
                    "Luminate",
                    "Proxyrack",
                    "Smartproxy",
                    "Netnut",
                    "Proxyguys",
                    "Spider",
                    "Geosurf"
                ],
                statuses: [
                    'good',
                    'bad'
                ],
                timeSelector:['12 AM - 1AM',
                    '1 AM - 2AM',
                    '2 AM - 3AM',
                    '3 AM - 4AM',
                    '4 AM - 5AM',
                    '5 AM - 6AM',
                    '6 AM - 7AM',
                    '7 AM - 8AM',
                    '8 AM - 9AM',
                    '9 AM - 10AM',
                    '10 AM - 11AM',
                    '11 AM - 12PM',
                    '12 PM - 1PM',
                    '1 PM - 2PM',
                    '2 PM - 3PM',
                    '3 PM - 4PM',
                    '4 PM - 5PM',
                    '5 PM - 6PM',
                    '6 PM - 7PM',
                    '7 PM - 8PM',
                    '8 PM - 9PM',
                    '9 PM - 10PM',
                    '10 PM - 11PM',
                    '11 PM - 12AM'],
                proxyDataset: {},
                pageSettings: {pageSize: 100},
                groupSettings:  {
                    showDropArea: false,
                    disablePageWiseAggregates: true,
                    columns: ['date', 'provider','time_frame']
                },
                filterSettings:{
                   immediateModeDelay:500,
                    mode:'Immediate'
                },
                searching: false,
                showExportButton: false,
                reportLoading: false,
                pTemplate: function () {
                    return {
                        template : pTemplate
                    }
                }
            }
        },
        created: function(){
            this.load();
            this.loadClients();
            this.loadProducts();
            this.GetClientsList();
            window.addEventListener("keydown",this.windowListener);
            this.$root.preloader = false;
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        provide: {
            grid: [
                Page,
                Sort,
                ExcelExport,
                Group,
                Filter,
                Resize
            ]
        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && !this.searching){
                    this.search();
                }
            },
            actionHandler: function(args) {
                console.log(args);
            },
            load: function () {
                //Load the campaign
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                const dateString = [year, month, day].join('-');
                this.filter.from = dateString;
                this.filter.to = dateString;
            },
            loadClients: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params:request}).then(function(resp){
                    //Store the stats
                    this.clients = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
            loadProducts: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.products = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
            GetClientsList: function(){
                this.cities = [];
                var request = {'apikey': this.$root.apikey};

                return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.dropdownClients = (resp.data)? resp.data.data : [];
                }.bind(this));
            },
            search: function(){
                this.searching = true;
                this.proxyDataset = {};
                this.report = [];
                this.reportLoading = true;

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};

                if(this.filter.from) request.filters.from = this.filter.from;
                if(this.filter.to) request.filters.to = this.filter.to;
                if(this.filter.provider) request.filters.provider = this.filter.provider;
                if(this.filter.status) request.filters.status = this.filter.status;
                if(this.filter.client) request.filters.client_id = this.filter.client;
                if(this.filter.product) request.filters.product_id = this.filter.product;

                axios.get(`${this.$root.serverUrl}/admin/reports/proxy/status-check`,{params:request}).then((resp) => {
                    if(resp.data.error){
                        this.reportLoading = false;
                        swal({title:'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                    }else{
                        const result = resp.data.data;
                        if(result.length > 0){
                            for (let i in result) {
                                console.log(result[i])
                                this.stage(result[i]);
                            }
                            if(this.filter.time == "") { // check if the time is any empty string and dont not filter
                                for (const item in this.proxyDataset) this.report.push(this.proxyDataset[item]);
                            }else{
                                for (const item in this.proxyDataset)
                                    if(this.proxyDataset[item].time_frame == this.filter.time)// filter is date match
                                        this.report.push(this.proxyDataset[item]);
                            }
                            this.showExportButton = true;
                            this.reportLoading = false;
                        }else{
                            this.reportLoading = false;
                        }
                    }
                    this.searching = false;
                }).catch((err) => {
                    this.reportLoading = false;
                    this.searching = false;
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                });
            },
            stage: function (dataset) {

                // console.log(dataset.date)
                const geoHash = this.hashGeoTracking(
                    dataset.data.country,
                    dataset.data.state_name,
                    dataset.data.city,
                    dataset.data.ip
                );
                const errorHash = this.hashErrorTracking(geoHash,dataset.errorCode);
                if (!this.proxyDataset.hasOwnProperty(errorHash)) {
                    this.proxyDataset[errorHash] = {
                        id: dataset.proxy_id,
                        provider: dataset.provider,
                        time_frame: this.getTimeFrame(dataset.date),
                        date: this.getDate(dataset.date),
                        error_code:dataset.errorCode,
                        error_count:0,
                        data_country:dataset.data.country || 'N/A',
                        data_state:dataset.data.state_name || 'N/A',
                        data_city:dataset.data.city || 'N/A',
                        data_ip:dataset.data.ip || 'N/A',
                        details:[],
                        lastCheck:dataset.data.lastRun || 'N/A',
                        nextCheck:dataset.data.newRun || 'N/A',
                    };
                }
                this.proxyDataset[errorHash].error_count++;
                this.proxyDataset[errorHash].details.push({
                    id:dataset._id,
                    date:this.getDate(dataset.date),
                    time:this.getTime(dataset.date),
                    provider:dataset.provider,
                    error_text:dataset.errorText,
                    status: dataset.type,
                    url:dataset.url,
                    headers: JSON.stringify(dataset.headers,null,'  '),
                    request_headers: JSON.stringify(dataset.requestHeaders,null,'  '),
                    data_ip:dataset.data.ip,
                    data_port:dataset.data.port,
                    data_username:dataset.data.username,
                    data_password:dataset.data.password,
                    data_status:dataset.data.status,
                    data_protocol:dataset.data.protocol,
                    data_country: dataset.data.country,
                    data_state:dataset.data.state_name,
                    data_city:dataset.data.city,
                    data_weight: dataset.data.weight,
                    lastCheck:dataset.data.lastRun,
                    nextCheck:dataset.data.newRun,

                });
            },
            exportToExcel:function(){
                let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign} ${this.filter.client}`;
                fileName = fileName.replace(/ /g,"_");
                fileName = fileName.replace(/:/g,"_");
                fileName += ".xlsx";
                let excelExportProperties = {
                    fileName,
                    dataSource:this.report,
                    includeHiddenColumn: true
                };
                this.$refs.grid.excelExport(excelExportProperties);
            },
            getDate: function(dateTime){
                let d = new Date(dateTime),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [month, day, year].join('/');
            },
            getTime: function(dateTime){
                let d = new Date(dateTime);
                return d.toLocaleTimeString('en-US');
            },
            getTimeFrame: function(dateTime){
                const timeFrameMap = [
                    '12 AM - 1AM',
                    '1 AM - 2AM',
                    '2 AM - 3AM',
                    '3 AM - 4AM',
                    '4 AM - 5AM',
                    '5 AM - 6AM',
                    '6 AM - 7AM',
                    '7 AM - 8AM',
                    '8 AM - 9AM',
                    '9 AM - 10AM',
                    '10 AM - 11AM',
                    '11 AM - 12PM',
                    '12 PM - 1PM',
                    '1 PM - 2PM',
                    '2 PM - 3PM',
                    '3 PM - 4PM',
                    '4 PM - 5PM',
                    '5 PM - 6PM',
                    '6 PM - 7PM',
                    '7 PM - 8PM',
                    '8 PM - 9PM',
                    '9 PM - 10PM',
                    '10 PM - 11PM',
                    '11 PM - 12AM'
                ];
                let d = new Date(dateTime);
                return timeFrameMap[d.getHours()];
            },
            hashGeoTracking: function(country,state,city,ip){
                return sha1(`${country}${state}${city},${ip}`);
            },
            hashErrorTracking: function(geoHash,errorCode){
                return sha1(`${geoHash}${errorCode}`);
            }
        }
    }
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>
